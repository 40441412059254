import React, { useState } from 'react';

import { FaTimes } from 'react-icons/fa';

import {
  Box,
  Flex,
  Img,
  Text,
  Button,
  useDisclosure
} from '@chakra-ui/react'

import {
  drawings,
  squares,
  desenho19,
  desenho18,
  desenho17,
  desenho16,
  desenho15,
  desenho14,
  desenho13,
  desenho12,
  desenho11,
  desenho10,
  desenho9,
  desenho8,
  desenho7,
  desenho6,
  desenho5,
  desenho4,
  desenho3,
  desenho2,
  desenho1,
  fullDesenho19,
  fullDesenho18,
  fullDesenho17,
  fullDesenho16,
  fullDesenho15,
  fullDesenho14,
  fullDesenho13,
  fullDesenho12,
  fullDesenho11,
  fullDesenho10,
  fullDesenho9,
  fullDesenho8,
  fullDesenho7,
  fullDesenho6,
  fullDesenho5,
  fullDesenho4,
  fullDesenho3,
  fullDesenho2,
  fullDesenho1,
} from '../../assets';

import { Link } from './styles'

const thumbnail = [
  { title: 'Erza Belx', version: null, image: desenho18 },
  { title: 'Bebe Rexha', version: null, image: desenho17 },
  { title: 'Maisie Williams', version: null, image: desenho19 },
  { title: 'Audrey Beukelman', version: null, image: desenho16 },
  { title: 'Menina Black and White', version: null, image: desenho15 },
  { title: 'Chris Cornell', version: null, image: desenho4 },
  { title: 'Lana Del Rey', version: null, image: desenho3 },
  { title: 'Chester Bennington', version: null, image: desenho2 },
  { title: 'Primeiro Digital', version: null, image: desenho1 },
  { title: 'Borboleta', version: '3D', image: desenho5 },
  { title: 'Alexandra Daddario', version: '2', image: desenho6 },
  { title: 'Alexandra Daddario', version: '1', image: desenho7 },
  { title: 'Primeiro com Caneta', version: null, image: desenho8 },
  { title: 'Paisagem com Canetinha', version: null, image: desenho9 },
  { title: 'Bill Cipher - Canetinha', version: null, image: desenho10 },
  { title: 'Sarah', version: null, image: desenho11 },
  { title: 'Emily Rudd', version: null, image: desenho12 },
  { title: 'Nascente', version: null, image: desenho13 },
  { title: 'Zoro', version: null, image: desenho14 },
];

const fullResolution = [
  { title: 'Erza Belx', version: null, image: fullDesenho18 },
  { title: 'Bebe Rexha', version: null, image: fullDesenho17 },
  { title: 'Maisie Williams', version: null, image: fullDesenho19 },
  { title: 'Audrey Beukelman', version: null, image: fullDesenho16 },
  { title: 'Menina Black and White', version: null, image: fullDesenho15 },
  { title: 'Chris Cornell', image: fullDesenho4 },
  { title: 'Lana Del Rey', image: fullDesenho3 },
  { title: 'Chester Bennington', image: fullDesenho2 },
  { title: 'Primeiro Digital', image: fullDesenho1 },
  { title: 'Primeiro Digital', image: fullDesenho5 },
  { title: 'Primeiro Digital', image: fullDesenho6 },
  { title: 'Primeiro Digital', image: fullDesenho7 },
  { title: 'Primeiro Digital', image: fullDesenho8 },
  { title: 'Primeiro Digital', image: fullDesenho9 },
  { title: 'Primeiro Digital', image: fullDesenho10 },
  { title: 'Primeiro Digital', image: fullDesenho11 },
  { title: 'Primeiro Digital', image: fullDesenho12 },
  { title: 'Primeiro Digital', image: fullDesenho13 },
  { title: 'Zoro', image: fullDesenho14 },
];

export function Home() {
  const [startPosition, setStartPosition] = useState(0);

  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      {isOpen && (
        <Box
          position={'fixed'}
          top={'0px'}
          left={'0px'}
          width={'100%'}
          height={'100%'}
          zIndex={'9'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          background={'rgba(0,0,0,0.95)'}
        >
          <Button
            variant={'link'}
            onClick={onClose}
            position={'absolute'}
            right={'30px'}
            top={'30px'}
          >
            <FaTimes  />
          </Button>
          <Img src={fullResolution[startPosition].image} maxH={'100%'} maxW={'100%'} />
        </Box>
      )}
      {/* <Modal isOpen={isOpen} onClose={onClose} size={"full"}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton color={"#ccc"} />
          <ModalBody
            w={"100%"}
            height={"100%"}
            padding={"0px"}
            margin={"0px"}
            display={"flex"}
            background={"#000"}
            alignItems={"center"}

          >
            <Box maxH={'100%'} maxW={'100%'}>
              
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal> */}
      <Box
        w={"100%"}
        h={"100%"}
      >
        <Box
          display={["flex", "grid"]}
          flexDirection={["column", "inherit"]}
          gridTemplateColumns={"50% 50%"}
          position={"relative"}
          height={"100%"}
        >
          <Box
            position={"absolute"}
            w={["40px", "90px"]}
            h={"100%"}
            background={"#000"}
            right={"0px"}
          >
            <Box
              w={["10px", "20px"]}
              h={"100%"}
              background={"#fff"}
              marginLeft={["10px","20px"]}
            />
          </Box>
          <Flex
            w={"100%"}
            h={["40%", "100%"]}
            alignItems={["initial", "center"]}
            justifyContent={["initial", "center"]}
          >
            <Box
              position={"relative"}
              w={["60%", "480px"]}
              h={["auto", "580px"]}
            >
              <Img
                src={drawings}
                position={"absolute"}
                w={"100%"}
                zIndex={1}
                blendMode={"luminosity"}
              />
              <Img
                src={squares}
                position={"absolute"}
                w={"100%"}
              />
            </Box>
          </Flex>
          <Flex
            justifyContent={"center"}
            alignItems={"center"}
            marginLeft={"-90px"}
          >
            <Flex
              w={["70%","495.89px"]}
              flexDirection={"column"}
              alignItems={"end"}
            >
              <Text
                fontFamily={"Arial"}
                fontSize={["30px", "40px"]}
                color={"#A6A6A6"}
                w={["80%", "451.35px"]}
                textAlign={"right"}
              >
                Esse site é um portfólio dedicado a um dos meus hobby's que é a arte digital e grafite
              </Text>
              <Text
                fontSize={"24px"}
                color={"#A1A1A1"}
                fontFamily={"Hunter River"}
                marginTop={"20px"}
              >
                Carlos Eduardo Neres de Arruda
              </Text>
              <Link
                background={"#000"}
                w={"50%"}
                marginTop={"40px"}
                padding={"30px 0px"}
                color={"#fff"}
                _hover={{
                  background: "#000"
                }}
                _active={{
                  background: "#333"
                }}
                href="#drawings"
              >
                Explorar
              </Link>
            </Flex>
          </Flex>
        </Box>
      </Box>
      <Box
        w={"100%"}
        minHeight={"100%"}
        background={"#000"}
        position={"relative"}
        id="drawings"
      >
        <Box
          position={"absolute"}
          w={["40px", "90px"]}
          h={"100%"}
          background={"#fff"}
          right={"0px"}
          top={"0px"}
        >
          <Box
            w={["10px", "20px"]}
            h={"100%"}
            background={"#000"}
            marginLeft={["10px", "20px"]}
          />
        </Box>

        <Box
          display={"grid"}
          gridTemplateColumns={["50% 50%", "33.333% 33.333% 33.333%"]}
          w={["calc(100% - 50px)", "calc(100% - 90px)"]}
        >
          {thumbnail.map((res, i) => {
            return (
              <Box
                key={"item_"+i}
                w={"100%"}
                filter={"grayscale(100%)"}
                overflow={"hidden"}
                cursor={"pointer"}
                transition={"0.5s ease-in-out"}
                _hover={{
                  filter: "grayscale(0%)"
                }}
                onClick={() => {
                  setStartPosition(i);

                  onOpen()
                }}
              >
                <Img
                  w={"100%"} src={res.image}
                  transition={"0.5s ease-in-out"}
                  _hover={{
                    transform: "scale(1.1)",
                  }}
                />
              </Box>
            )
          })}
        </Box>
      </Box>
    </>
  );
}