import { createGlobalStyle } from 'styled-components';

import hunterRiver from '../assets/HunterRiverRegular.otf';
import theFlybirds from '../assets/TheFlybirds.ttf';

export default createGlobalStyle`
  html {
    scroll-behavior: smooth !important;
  }
  
  #root {
    height: 100%;
    width: 100%;
    position: fixed;
  }

  @font-face {
    font-family: 'Hunter River';
    src: local('Hunter River'), url(${hunterRiver}) format('truetype');
  }
  @font-face {
    font-family: 'The Fly birds';
    src: local('The Fly birds'), url(${theFlybirds}) format('truetype');
  }
`;