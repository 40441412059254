import * as React from 'react'

import { ChakraProvider, Box } from '@chakra-ui/react'

import GlobalStyles from './styles/global';

import { Home } from './screens/Home'

function App() {
  return (
    <ChakraProvider>
      <GlobalStyles />
      <Box
        h={"100%"}
        w={"100%"}
        background={"#fff"}
        position={"absolute"}
        overflowY={"scroll"}
      >
        <Home />
      </Box>
    </ChakraProvider>
  )
}

export default App;